import * as React from "react"
// import {UseLocation} from "react-dom";

// import { graphql } from "gatsby"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
// import { StaticImage } from "gatsby-plugin-image"

import 'bootstrap/dist/css/bootstrap.min.css';

import Logo from "../../images/rvlogo.png"

const MainMenu = () => {
const [navBg, setNavBg] = React.useState(0)
const [linkStyle, setLinkStyle] = React.useState(0)

React.useEffect(() => {
   
    
    window.onscroll = () => {
        handleScroll()
    }

  }, []);
    
    const handleScroll=()=>{
        if(window.location.pathname === "/"){
        if (window.pageYOffset > 120) {
            setNavBg("whiteBg");  
            setLinkStyle("LinkDark") 
        }else{
            setNavBg("TransBg");
            setLinkStyle("LinkWhite") 
        }
    }
       
      }
    return (
    <>
        <Navbar expand="lg" fixed="top" id={[navBg]}>
        <Container>
            <Navbar.Brand href="/">
            <img
                    className="logo"
                    src={Logo}
                    alt="A Gatsby astronaut"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" style={{ width: "100%" }} id={[linkStyle]}>
                <Nav.Link href="/" className="scroll">Home</Nav.Link>
                <Nav.Link href="/about-us" className="scroll">About US </Nav.Link>
                <Nav.Link href="/#team" className="scroll">Team</Nav.Link>
                <Nav.Link href="/#services" className="scroll">What we do</Nav.Link>
                {/* <Nav.Link href="#home" className="scroll">Work</Nav.Link>
                <Nav.Link href="#link" className="scroll">Prices</Nav.Link> */}
                {/* <Nav.Link href="/#home" className="scroll">Blog</Nav.Link> */}
                <Nav.Link href="/#contact" className="scroll">Get in touch</Nav.Link>
                <Nav.Link href="tel:+441615052760" className="callUs scroll">CALL US &nbsp;0161 50 52 760</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
   </>
)
    }

export default MainMenu
